var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-2",attrs:{"color":"#FCFDFE"}},[_c('v-data-table',{staticClass:"elevation-1 striped",attrs:{"dense":"","headers":_vm.columns,"items":_vm.list,"loadLines":5,"loading":_vm.loading,"footer-props":{
                     itemsPerPageOptions: [10,50,100,-1],
                     itemsPerPageText: _vm.L('Registros por Pagina'),
                     pageText: '{0}-{1} de {2}',
                     showFirstLastPage: true,
                   }},scopedSlots:_vm._u([(_vm.loading)?{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"table-row-divider@5"}})]},proxy:true}:null,{key:"item.total_wt",fn:function(ref){
                   var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(parseFloat(item.total_wt)))+" ")]}},{key:"item.attended_date",fn:function(ref){
                   var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.attended_date,"from", "now"))+" ")]}},{key:"item.order_date",fn:function(ref){
                   var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.order_date,"from", "now"))+" ")]}},{key:"item.state",fn:function(ref){
                   var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.state.extra,"dark":"","small":""}},[_vm._v(_vm._s(item.state.name))])]}},{key:"item.action",fn:function(ref){
                   var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                   var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","loading":_vm.loading,"text":"","icon":""},on:{"click":function($event){return _vm.showOrder(item)}}},on),[_c('v-icon',{attrs:{"large":""}},[_vm._v("visibility")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver Pedido")])])]}},{key:"no-data",fn:function(){return [_c('v-row',{staticClass:"d-flex flex-wrap text-center justify-space-around my-12",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-btn',{attrs:{"icon":"","x-large":""}},[_c('v-icon',{attrs:{"size":"100px","color":"primary"}},[_vm._v(_vm._s(_vm.room.icon))])],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('h3',{staticClass:"mt-3"},[_vm._v("No hay ordenes pendientes!")])])],1)]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }